import React from "react";
import img1 from "../logo192.png";
//import { useNavigate } from "react-router-dom";
//import { Amplify, Auth} from 'aws-amplify';
//import { withAuthenticator } from '@aws-amplify/ui-react';
import {
  // /Flex,
//  Heading,
  Image,
//  View,
} from "@aws-amplify/ui-react";
//import awsExports from '../aws-exports';

//Amplify.configure(awsExports);



//async function signOut() {
//    try {
//      await Auth.signOut();
//    } catch (error) {
//      console.log('error signing out: ', error);
 //   }
 // }


function Nav() {


    //const navigate = useNavigate();

               



    return (
        <nav className='navbar' >
            <h2>
            <Image
  alt="img1"
  src={img1}
  backgroundColor="initial"
  height='18px'
  width='18px'
  borderRadius="2px"
  opacity="100%"
  marginRight="10px"
  //onClick={() => alert('📸 Say cheese!')}
/>

              
              comburent.io   

              
            

            </h2>
            
            
           
            <div className='navbarRight'>
              <h2>
            <span style={{paddingLeft:'20px', fontSize:'16px', opacity:'.75'}}>roark@comburent.io</span>
            </h2>
            </div>
            
            
        </nav>
    );
};


export default Nav;