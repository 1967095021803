import Nav from "./Nav";

import React, { } from "react";
//import { useNavigate } from "react-router-dom";
//import { Auth, API , graphqlOperation } from 'aws-amplify'
//import { } from '../graphql/mutations'
//import { } from '../graphql/queries'
//import { withAuthenticator  } from '@aws-amplify/ui-react';

//import {
//  Flex,
//  Heading,
//  Image,
//  View,
//} from "@aws-amplify/ui-react";

//import img1 from "../logo192.png";




function Home() {



    //const navigate = useNavigate();
   


    




    return (
        <>
            <Nav />

        <div className="Body">

		
<br></br><br></br>
		<h1 style={{fontSize:'6.2vw', marginRight:'10vw', marginLeft:'10vw'}}>
	    cloud application development and services
		 </h1>


            </div>

        </>
    );
};




export default Home;
