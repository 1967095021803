import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import '@aws-amplify/ui-react/styles.css';
import './App.css';

function App() {


  useEffect(() => {
    document.title = "comburent.io"
  }, []);


  return (
    <div className="App">

            <BrowserRouter>
                <Routes>
                     
                     <Route path='/' element={<Home />} />
                    
                </Routes>
            </BrowserRouter>


    </div>
  );
}

export default App;
